<template>
<div class="banner-main">
    <div class="banner-point">
        <div class="model-title">积分范围</div>
        <div class="point-list">
            <span v-for="(item, index) in pointList" :key="index" @mouseenter="getlist(item)">
                {{ item.name }}
            </span>
        </div>
        <div class="jf-producnt">
            <div class="pro" v-for="(item, index) in integralNumList" :key="index" @click="detail(item.id)">
                <img :src="item.pictureUrl" alt="" class="pro-img" />
                <!-- <img src="@/assets/images/jf-new.png" alt="" class="pro-tip" /> -->
                <!-- <img src="@/assets/images/jf-sale.png" alt="" class="pro-tip" /> -->
                <div class="pro-title">{{item.chineseName}}</div>
                <div class="pro-price">{{item.recommendRetailPrice}} <span>积分</span></div>
            </div>

        </div>
        <div class="more" @click="more">查看更多</div>
    </div>
</div>
</template>

<script>
import {
    getproductlist, //商品列表
} from "@/api/api";

export default {
    props: ["cmsData"],
    data() {

        return {
            menuIndex: 0,
            integralNumList: [],
            pointList: [{
                    name: "10000以下",
                    minPoint: "0",
                    maxPoint: "10000",
                },
                {
                    name: "10001-30000",
                    minPoint: "10001",
                    maxPoint: "30000",
                },
                {
                    name: "30001-50000",
                    minPoint: "30001",
                    maxPoint: "50000",
                },
                {
                    name: "50001-100000",
                    minPoint: "50001",
                    maxPoint: "100000",
                },

                {
                    name: "100001-200000",
                    minPoint: "100001",
                    maxPoint: "200000",
                },
                {
                    name: "200000以上",
                    minPoint: "200000",
                    maxPoint: "200000000000000000000000000000000000000000000",
                },

            ],
        };
    },

    methods: {
        async getlist(item) {
            let params = {
                //商品id
                id: "",
                //品牌名称
                brandName: "",
                //分类名称
                cName: "",
                categoryName: "",
                //商品名称
                chineseName: "",
                //标题
                subtitle: "",
                //积分
                recommendRetailPrice: "",
                //图片url
                pictureUrl: "",
                //最大最小积分
                maxPoint: item.maxPoint,
                minPoint: item.minPoint,
                //分页参数
                // pageNum: pageNum,
                pageNum: 1,
                pageSize: "4",
                //排序
                status: "",
            };

            let productRes = await getproductlist(params);
            if (productRes.code == "0000") {
                this.integralNumList = productRes.result.list;
            }
        },
        more() {
            this.$router.push({
                path: "/products/productList",

            });
        },
        detail: function (id) {
            let routeUrl = this.$router.resolve({
                path: "/products/productDetails?id=",
                query: {
                    id: id,
                },
            });
            window.open(routeUrl.href, "_blank");
        },
    },

    mounted() {
        this.getlist(1);
    },
    created: function () {},
};
</script>

<style lang="scss">
.model-title {
    font-size: 24px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
}

.model-title:before,
.model-title:after {
    content: "";
    display: inline-block;
    width: 4px;
    height: 24px;
    background: #bca06b;
    margin: auto 12px;
    vertical-align: sub;
}
</style><style lang="scss" scoped>
.menu-item-selected {
    background: #ac8c4f;
    color: #fff;
}

.banner-main {
    width: 1200px;
    //   height: 580px;
    margin: auto;
    margin-bottom: 40px;
    margin-top: 40px;

    .banner-point {
        width: 100%;

        .point-list {
            width: 60%;
            //   height: 56px;
            //   background-color: #f3f9ff;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            margin: auto;

            span {
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                color: #9a9a9a;
                line-height: 30px;
                width: 100px;
                height: 30px;
                background: #e2e2e2;
                cursor: pointer;
            }

            span:hover {
                background: #ac8c4f;
                color: #fff;
            }
        }

        .point-more {
            cursor: pointer;
            line-height: 38px;
            font-size: 14px;
            color: #999999;

            img {
                margin-left: 4px;
                vertical-align: sub;
            }
        }
    }

    .more {
        width: 160px;
        height: 40px;
        border: 1px solid #ac8c4f;
        border-radius: 3px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #ac8c4f;
        line-height: 40px;
        margin: auto;
        cursor: pointer;
    }
}

.jf-producnt {
    display: flex;
    // justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 30px;

    .pro {
        position: relative;
        width: 280px;
        cursor: pointer;
        margin: 0 10px;

        .pro-img {
            width: 280px;
            height: 300px;
        }

        .pro-img:hover {
            box-shadow: 0 3px 8px rgba($color: #000000, $alpha: .3);
            transition: box-shadow 0.3s linear;
        }

        .pro-tip {
            position: absolute;
            width: 48px;
            top: 10px;
            right: 10px;
        }

        .pro-title {
            width: 186px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            color: #333333;
            line-height: 18px;
            font-size: 14px;
            margin: auto;
            margin-top: 20px;
        }

        .pro-price {
            height: 40px;
            font-size: 28px;
            // font-weight: 700;
            text-align: center;
            color: #ac8c4f;
            line-height: 36px;
            padding-top: 10px;

            span {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
            }
        }
    }
}
</style>

<template>
<div class="list-main">
    <div style="padding:20px;background: #fff;">
        <div class="list-filter">
            <!-- <div class="filter-item">
            <div class="item-title">品牌：</div>
            <div class="item-content">
                <span class="brand-item content-item" :class="index == brandIndex ? 'brand-selected' : ''" v-for="(item, index) in brandList" :key="index" @click="choseBrand(index)">{{ item.name }}</span>
            </div>
        </div> -->
            <!-- <div class="filter-item">
            <div class="item-title">分类：</div>
            <div class="item-content">
                <span class="category-item content-item" :class="index == typeIndex ? 'brand-selected' : ''" v-for="(item, index) in typeList" :key="index" @click="choseType(index)">首饰</span>
            </div>
        </div> -->
            <div class="filter-item">
                <div class="item-title">积分：</div>
                <div class="item-content">
                    <span class="category-item content-item" :class="index == pointIndex ? 'brand-selected' : ''" v-for="(item, index) in pointList" :key="index" @click="chosePoint(index,item.maxPoint,item.minPoint)">{{ item.name }}</span>
                </div>
            </div>
            <div class="filter-item">
                <div class="item-title">排序：</div>
                <div class="item-content">
                    <!-- <span class="category-item content-item" :class="index == filterIndex ? 'brand-selected' : ''" v-for="(item, index) in filterList" :key="index" @click="choseFilter(index,item.status)">{{ item.name }}</span> -->
                    <div class="category-item content-item" data-index="0" @click="screen(0)">
                        <span :class="[tabIndex == 0 ? 'brand-selected' : '']">价格</span>
                        <img src="@/assets/images/sou.png" class="icon-active" v-show="tabIndex != 0" />
                        <img src="@/assets/images/souend.png" class="icon-active" v-show="!priceFlag && tabIndex == 0" />
                        <img src="@/assets/images/souTop.png" class="icon-active" v-show="priceFlag && tabIndex == 0" />
                    </div>
                    <div class="category-item content-item" data-index="1" @click="screen(1)">
                        <span :class="[tabIndex == 1 ? 'brand-selected' : '']">销量</span>
                        <img src="@/assets/images/sou.png" class="icon-active" v-show="tabIndex != 1" />
                        <img src="@/assets/images/souend.png" class="icon-active" v-show="!saleFlag && tabIndex == 1" />
                        <img src="@/assets/images/souTop.png" class="icon-active" v-show="saleFlag && tabIndex == 1" />
                    </div>
                    <div class="category-item content-item" data-index="2" @click="screen(2)">
                        <span :class="[tabIndex == 2 ? 'brand-selected' : '']">好评</span>
                        <img src="@/assets/images/sou.png" class="icon-active" v-show="tabIndex != 2" />
                        <img src="@/assets/images/souend.png" class="icon-active" v-show="!commentFlag && tabIndex == 2" />
                        <img src="@/assets/images/souTop.png" class="icon-active" v-show="commentFlag && tabIndex == 2" />
                    </div>
                    <div class="category-item content-item" data-index="3" @click="screen(3)">
                        <span :class="[tabIndex == 3 ? 'brand-selected' : '']">上架时间</span>
                        <img src="@/assets/images/sou.png" class="icon-active" v-show="tabIndex != 3" />
                        <img src="@/assets/images/souend.png" class="icon-active" v-show="!shelvesFlag && tabIndex == 3" />
                        <img src="@/assets/images/souTop.png" class="icon-active" v-show="shelvesFlag && tabIndex == 3" />
                    </div>
                </div>
            </div>
        </div>
        <div class="products-list">
            <div class="list-item" v-for="(item,index) in productlist" :key="index" @click="detail(item.id)">
                <div class="item-pic">
                    <el-image :src="item.pictureUrl" class="item-img"></el-image>
                </div>
                <div class="item-content">
                    <div class="content-title">
                        {{ item.chineseName }}
                    </div>
                    <span class="content-subtitle">{{ item.subtitle }}</span>
                    <span class="content-point">{{ item.recommendRetailPrice }}积分</span>
                </div>
            </div>
        </div>
        <div class="list-pagination">
            <el-pagination background layout="prev, pager, next" :page-sizes="[5, 15, 25, 30]" :total="total" :page-size="12" @current-change="changePage"></el-pagination>
        </div>
    </div>

</div>
</template>

<script>
import {
    getproductlist, //商品列表
} from "@/api/api";
export default {
    name: "App",
    data() {
        return {

            productlist: [],
            brandIndex: -1,
            typeIndex: -1,
            pointIndex: -1,
            tabIndex: 0,
            brandList: [
                "阿里巴巴",
                "阿里巴巴",
                "阿里巴巴",
                "阿里巴巴",
                "阿里巴巴",
                "阿里巴巴",
            ],
            typeList: ["首饰", "服饰", "食物"],
            pointList: [{
                    name: "10000以下",
                    minPoint: "1",
                    maxPoint: "10000"
                },
                {
                    name: "10001-30000",
                    minPoint: "10001",
                    maxPoint: "30000"
                },
                {
                    name: "30001-50000",
                    minPoint: "30001",
                    maxPoint: "50000"
                },
                {
                    name: "50001-100000",
                    minPoint: "50001",
                    maxPoint: "100000"
                },
                {
                    name: "100001-200000",
                    minPoint: "100001",
                    maxPoint: "200000"
                },
                {
                    name: "200000以上",
                    minPoint: "200000",
                    maxPoint: "10000000000000000000000000000000000000"
                },
            ],
            filterList: [{
                    name: "新品",
                    status: "1"
                },
                {
                    name: "好评",
                    status: "2"
                },
                {
                    name: "销量",
                    status: "3"
                },
                {
                    name: "积分从低到高",
                    status: "4"
                },
                {
                    name: "积分从高到低",
                    status: "5"
                }
            ],
            cName: "", //分类名称
            categoryName: "", //类目名称
            brandName: "", //品牌名称
            chineseName: "", //商品名称
            maxPoint: "", //最大积分
            minPoint: "", //最小积分
            status: "", //排序
            total: 0, //总条数
            sortPrice: true,
            priceFlag: false, //价格排序标志
            saleFlag: false, //销量排序标志 
            commentFlag: false, //评论排序标志 
            shelvesFlag: false, //上架标志 
        };
    },

    methods: {
        screen: function (index) {
            this.start = 1;
            if (index == 0) {
                this.tabIndex = 0;
                this.priceFlag = !this.priceFlag;
                if (this.sortPrice) {
                    this.status = 1;
                } else {
                    this.status = 2;
                }
                this.sortPrice = !this.sortPrice;
                this.classification(1);
            } else if (index == 1) {
                this.tabIndex = 1;
                this.saleFlag = !this.saleFlag;
                if (this.sortPrice) {
                    this.status = 3;
                } else {
                    this.status = 4;
                }
                this.sortPrice = !this.sortPrice;
                this.classification(1);
            } else if (index == 2) {
                this.tabIndex = 2;
                this.commentFlag = !this.commentFlag;
                if (this.sortPrice) {
                    this.status = 5;
                } else {
                    this.status = 6;
                }
                this.sortPrice = !this.sortPrice;
                this.classification(1);
            } else if (index == 3) {
                this.tabIndex = 3;
                this.shelvesFlag = !this.shelvesFlag;
                if (this.sortPrice) {
                    this.status = 7;
                } else {
                    this.status = 8;
                }
                this.sortPrice = !this.sortPrice;
                this.classification(1);
            }
        },
        productListInit() {
            // 分类
            if (this.$route.query.cName !== undefined) {
                this.cName = this.$route.query.cName;
            }

            // 类目
            if (this.$route.query.categoryName !== undefined) {
                this.categoryName = this.$route.query.categoryName;
            }

            // 搜索
            if (this.$route.query.chineseName !== undefined) {
                this.chineseName = this.$route.query.chineseName
            }

            // 品牌
            if (this.$route.query.brandName !== undefined) {
                this.brandName = this.$route.query.brandName
            }
            // 品牌
            if (this.$route.query.status !== undefined) {
                this.status = this.$route.query.status
            }

            // 最大积分
            if (this.$route.query.maxPoint !== undefined) {
                this.maxPoint = this.$route.query.maxPoint
            }

            // 最低积分
            if (this.$route.query.minPoint !== undefined) {
                this.minPoint = this.$route.query.minPoint
            }
            // console.log("55555555555555555555555555")

            this.classification(1);
        },
        // async queryPageHaier() {
        //   let queryPageHaierRes = await queryPageHaierApi();
        // },

        //商品详情
        // detail: function (id) {
        //   this.$router.push({
        //     path: "/products/productDetails?id="+ id,
        //   });
        // },
        detail: function (id) {
            let routeUrl = this.$router.resolve({
                path: "/products/productDetails?id=" + id,
            });
            window.open(routeUrl.href, '_blank');
        },

        //商品分类列表
        async classification(pageNum) {
            let params = {
                //商品id
                id: "",
                //品牌名称
                brandName: this.brandName,
                //分类名称
                cName: this.cName,
                categoryName: this.categoryName,
                //商品名称
                chineseName: this.chineseName,
                //标题
                subtitle: "",
                //积分
                recommendRetailPrice: "",
                //图片url
                pictureUrl: "",
                //最大最小积分
                maxPoint: this.maxPoint,
                minPoint: this.minPoint,
                //分页参数
                // pageNum: pageNum,
                pageNum: pageNum,
                pageSize: "12",
                //排序
                status: this.status,
                showTerminal:2,
                showDesk:1
            };

            let productRes = await getproductlist(params);
            if (productRes.code == "0000") {
                this.productlist = productRes.result.list;
                this.brandList = productRes.result.rows;
                this.total = productRes.result.total;
            }
        },

        choseBrand(index) {
            // this.$set(this, "brandIndex", index);
            this.brandIndex = index;
        },
        choseType(index) {
            this.typeIndex = index;
        },
        chosePoint(index, maxPoint, minPoint) {
            this.pointIndex = index;
            this.maxPoint = maxPoint;
            this.minPoint = minPoint;
            this.classification(1);
        },
        choseFilter(index, status) {
            this.filterIndex = index;
            this.status = status;
            this.classification(1);
        },
        changePage(index) {
            this.classification(index);
            console.log(index)
        },
    },
    mounted() {
        this.productListInit();
    },
    watch: {
        $route(to, from) {
            console.log("000")
            this.cName = this.$route.query.cName;
            this.maxPoint = this.$route.query.maxPoint;
            this.minPoint = this.$route.query.minPoint;
            this.productListInit();
        }
    }

};
</script>

<style lang="scss" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #AC8C4F;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #AC8C4F;
    color: #fff;

}
.list-main {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
}

.icon-active {
    width: 20px;
    // height: 10px;
}

.list-filter {
    width: 100%;
    height: auto;
    // margin-top: 30px;
    border: 1px solid #e8e8e8;

    .filter-item {
        min-height: 45px;
        width: 100%;
        display: flex;
        flex-direction: row;

        .item-title {
            width: 110px;
            background-color: #f5f5f5;
            line-height: 45px;
            padding-left: 20px;
            color: #333;
            font-weight: bold;
        }

        .item-content {
            flex: 1;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            padding-left: 30px;

            .content-item {
                text-align: left;
                cursor: pointer;
                margin-right: 50px;
                line-height: 40px;
                color: #393939;
                display: flex;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .content-item:hover {
                color: #ac8c4f;
            }

            .brand-item {
                width: 10%;
            }

            .brand-selected {
                color: #ac8c4f;
            }

            .category-item {
                width: 10%;
            }
        }
    }

    .filter-item:not(:last-child) {
        border-bottom: 1px dashed #eeeeee;
    }
}

.products-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;

    .list-item {
        transition: box-shadow 0.3s linear;
        width: 25%;
        height: 400px;
        margin-bottom: 30px;
        align-items: center;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .item-pic {
            height: 280px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .item-img {
                width: 240px;
                height: 240px;
            }
        }

        .item-content {
            flex: 1;
            width: 240px;
            display: flex;
            flex-direction: column;

            .content-title {
                width: 100%;
                border-bottom: 1px solid #ededed;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 30px;
                color: #333;
            }

            .content-subtitle {
                padding-top: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                color: #999999;
            }

            .content-point {
                color: #ac8c4f;
                margin-top: 6px;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }

    .list-item:hover {
        box-shadow: 0 3px 8px rgba($color: #000000, $alpha: 0.3);
        transition: box-shadow 0.3s linear;
    }
}

.list-pagination {
    width: 100%;
    margin-top: 12px;
    display: flex;
    justify-content: center;
}
</style>

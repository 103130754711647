<template>
  <div class="banner-main">
    <div class="banner-top">
      <div class="banner-menu menu" @mouseleave="hideMenu()">
        <div
          class="menu-item"
          v-for="(item, index) in menuList"
          :key="index"
          @mouseenter="showMenu(index)"
        >
          <img style="padding-right: 5px" :src="item.iconUrl" alt="" />

          <span @click="goList(item.name)">{{ item.name }}</span>
          <!-- <img style="height: 10px; width: 5px" src="@/assets/images/icon_right.png" /> -->
          <!-- <div class="menu-children" v-if="showChildren==index" :style="{background: 'url('+menuList[menuIndex].pictureUrl+')' }">
                    <div class="children-img"  ></div>
                    <div class="children-item" @click="goList(children.name)" v-for="(children, index1) in menuList[menuIndex].categoryList" :key="index1">
                        <div><img style="width:60px;height:60px;border-radius: 60px;" :src="children.pictureUrl" alt=""></div>
                        <div style="font-size:16px">{{ children.name }}</div>
                    </div>
                </div> -->
          <div class="menu-children">
            <div class="menu-childrenitem" v-if="showChildren == index">
              <div
                class="children-item"
                @click="goList(children.name)"
                v-for="(children, index1) in menuList[menuIndex].categoryList"
                :key="index1"
              >
                <img
                  style="
                    width: 60px;
                    height: 60px;
                    border-radius: 60px;
                    margin-right: 15px;
                  "
                  :src="children.pictureUrl"
                  alt=""
                />

                <span style="font-size: 14px">{{ children.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-slider">
        <el-carousel style="width: 100%" height="485px">
          <el-carousel-item
            v-for="(item, index) in cmsData.bannerList"
            :key="index"
          >
            <el-image
              :src="item.img"
              style="cursor: pointer; height: 100%; width: 100%"
              @click="detail(item)"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { queryBeforeCategory, sysNotice, totalPoint } from "@/api/api";
export default {
  props: ["cmsData"],
  data() {
    return {
      animate: false,
      list: [],
      banner: "",
      menuList: [],
      menuIndex: null,
      showChildren: -1,
      totalPoint: "",
      bannerHeight: (document.documentElement.clientWidth / 1872) * 485 + "px",
      pointList: [
        {
          name: "10000以下",
          minPoint: "1",
          maxPoint: "10000",
        },
        {
          name: "10001-30000",
          minPoint: "10001",
          maxPoint: "30000",
        },
        {
          name: "30001-50000",
          minPoint: "30001",
          maxPoint: "50000",
        },
        {
          name: "50001-100000",
          minPoint: "50001",
          maxPoint: "100000",
        },
        {
          name: "100001-200000",
          minPoint: "100001",
          maxPoint: "200000",
        },
        {
          name: "200000以上",
          minPoint: "200000",
          maxPoint: "10000000000000000000000000000000000000",
        },
      ],
    };
  },

  methods: {
    showMenu(index) {
      this.menuIndex = index;
      console.log(index);
      this.showChildren = index;
    },
    hideMenu() {
      // console.log(1);
      this.showChildren = -1;
    },
    noticedetail(item) {
      // alert(item)
      // let routeUrl = this.$router.resolve({
      //     path: "/centre/noticeDetail",
      //     query: {
      //        notice: item
      //     }
      // });
      // window.open(routeUrl.href, '_blank');

      this.$router.push({
        path: "/centre/noticeDetail",
        query: {
          notice: item,
        },
      });
    },
    async count(item) {
      this.$router.push({
        path: "/products/productList",
        query: {
          maxPoint: item.maxPoint,
          minPoint: item.minPoint,
        },
      });
    },
    async queryBeforeCategory() {
      let params = {
        id: "1",
      };
      let res = await queryBeforeCategory(params);
      if (res.code == "0000") {
        this.menuList = res.data[0].categoryList;
        // console.log(this.menuList)
        // this.banner = res.data[0].categoryList.pictureUrl;
      }
    },
    async getNotice() {
      let params = {};
      const res = await sysNotice(params);
      // console.log(res)
      if (res.code == "0000") {
        this.list = res.data.rows;
      }
    },

    //商品详情
    // detail() {
    //   this.$router.push({
    //     path: "/products/productDetails?id=12",
    //   });
    // },
    detail: function (item) {
      if (item.id !== null) {
        let routeUrl = this.$router.resolve({
          path: "/products/productDetails?id=",
          query: {
            id: item.id,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
      if (item.type == 1) {
        let routeUrl = this.$router.resolve({
          path: "/products/productDetails?id=",
          query: {
            id: item.id,
          },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        // window.location.href = item.detailsUrl;
        window.open(item.detailsUrl);
      }
    },
    goList(name) {
      this.$router.push({
        path: "/products/productList",
        query: {
          categoryName: name,
        },
      });
    },
    gonovite() {
      const { href } = this.$router.resolve({
        path: "/centre/notice",
      });
      window.open(href, "_blank");
    },
    showMarquee: function () {
      this.animate = true;
      setTimeout(() => {
        this.list.push(this.list[0]);
        this.list.shift();
        this.animate = false;
      }, 500);
    },
    async upperFindTotalPoint() {
      //获取总积分
      let params = {
        memberId: localStorage.getItem("memberId"),
      };
      let totalRes = await totalPoint(params);
      if (totalRes.code == "0000") {
        this.totalPoint = totalRes.data;
      }
    },
  },

  mounted() {
    this.queryBeforeCategory();
    this.getNotice();
    this.upperFindTotalPoint();
  },
  created: function () {
    // 页面显示
    setInterval(this.showMarquee, 2000);
  },
};
</script>

<style lang="scss" scoped>
.menu-childrenitem {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.marquee {
  width: 92%;
  // height: 50px;
  align-items: center;
  color: #999;
  background-color: #fff;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;

  .marquee_title {
    // width: 90px;
    padding-left: 20px;
    height: 21px;
    font-size: 14px;
    // border-right: 1px solid #d8d8d8;
    display: flex;
    color: #333333;

    span {
      font-weight: 600;
    }
  }

  .marquee_box {
    display: block;
    position: relative;
    width: 60%;
    height: 30px;
    overflow: hidden;

    a {
      color: #999;
    }
  }

  .marquee_list {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .marquee_top {
    transition: all 0.5s;
    margin-top: -30px;
  }

  .marquee_list li {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    padding-left: 20px;
    color: #999999;
  }

  .marquee_list li span {
    padding: 0 2px;
  }
}

.children-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.children-item {
  display: flex;
  align-items: center;
  // width: 20%;
  color: #222;
  font-size: 14px;
  cursor: pointer;
  // margin-right: 40px;
  // margin-bottom: 20px;
  // padding: 20px 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 50px;
  font-weight: bold;
  text-align: center;
}

.children-item:hover {
  color: #ac8c4f;
}

.banner-main {
  width: 100%;
  //   height: 580px;
  // margin-bottom: 10px;
  background: #fff;

  .banner-top {
    // height: 485px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // margin-bottom: 10px;

    .banner-menu {
      // padding: 20px 0;
      width: 100%;
      height: 60px;
      line-height: 60px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: row;
      background: #fff;
      position: relative;

      .menu-item {
        // margin: 0 auto;
        width: 170px;
        // flex: 1;
        color: #333;
        display: flex;
        flex-direction: row;
        align-items: center;

        justify-content: center;
        cursor: pointer;

        span {
          // width: 130px;
          font-size: 16px;
          // font-weight:bold ;
          font-weight: 400;
        }
      }

      .menu-item:hover {
        background-color: #ac8c4f;
        color: #fff;
      }

      .menu-item img:hover {
        color: #fff;
      }

      .menu-children {
        // padding: 0px 174px;
        position: absolute;
        width: 100%;
        // height: 485px;
        background-color: #fff;
        top: 60px;
        right: 0px;
        z-index: 10;
        // padding:0 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        // border: 2px solid #2283e2;
      }
    }

    .banner-slider {
      height: 100%;
      width: 100%;
      // margin-left: 10px;
    }
  }

  .banner-point {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 28px 20px;
    background-color: #fff;

    .point-title {
      width: 96px;
      opacity: 1;
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: 38px;
    }

    .point-list {
      width: 1010px;
      //   height: 56px;
      //   background-color: #f3f9ff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;

      span {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: #333333;
        line-height: 38px;
        width: 120px;
        height: 38px;
        background: #f4f4f9;
        border-radius: 19px;
        cursor: pointer;
      }

      span:hover {
        background: #71a2f9;
        color: #ffffff;
      }
    }

    .point-more {
      cursor: pointer;
      line-height: 38px;
      font-size: 14px;
      color: #999999;

      img {
        margin-left: 4px;
        vertical-align: sub;
      }
    }
  }

  .banner-notive {
    width: 98%;
    height: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    color: #333333;
    border-bottom: 1px solid #eee;
    margin: auto;

    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    .lkmore {
      font-size: 14px;
      color: #4b89f8;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

.banner-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .title-left {
    width: 190px;
    height: 200px;

    img {
      height: 100%;
    }
  }

  .title-right {
    width: 1000px;
    margin-left: 10px;
    background-color: #fff;
    height: 200px;
  }
}

.title-main {
  background-color: #fff;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;

  a {
    text-align: center;
    font-size: 14px;
    font-weight: 600;

    img {
      width: 60px;
      margin: 0;
      margin-bottom: 10px;
    }
  }
}
</style>
